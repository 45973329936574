<template>
  <div>
    <pagoEmisionSuccess v-if="paramsAseguradora.isPago == 'true'" />
    <pagoEmisionError v-else />
  </div>
</template>
<script>
import greenCheck from "@/assets/greenCheck.png";
import { getCotizacionesByFiltros } from "@/services/cotizaciones/cotizaciones.service";
import { getEmisionByID,postLeadMascotaInter,updatePolizaManual, getFormatEstadoId } from "@/services/emision/emision.service";
import { getCampaingLeadsList } from "@/services/campaings/campaing.service";
import { sendPolizaMascota } from "@/services/mascota/mascota.service";
import pagoEmisionSuccess from "./pago/pagoEmisionSuccess.vue";
import pagoEmisionError from "./pago/pagoEmisionError.vue";
import moment from "moment";

export default {
  components: {
    pagoEmisionSuccess,
    pagoEmisionError,
  },

  data() {
    return {
      greenCheck: greenCheck,
      emisionId: 1,
      emision: {},
      paramsAseguradora: {},
      polizaData:{},
      campaniasLeads:[],
      requestToInter:{}
    };
  },
  methods: {
    irPoliza() {
      console.log("ir a poliza");
      //this.$router.push(`/detalles-emision/${this.emisionId}`);
    },
    async getCotizacion() {
      this.responseAseguradora = await getCotizacionesByFiltros({
        grouped: true,
        cotizacion_general: this.$route.params.cotizacionGeneral,
      });
    },
    async getEmision() {
      this.emision = await getEmisionByID(this.$route.params.emision_id);
      this.formatEmision();
    },
    async getCampaingLeadsList(){
      this.campaniasLeads = await getCampaingLeadsList()
    },

    async formatEmision() {
      this.requestToInter = JSON.parse(this.emision.request);
      const form = {
        active: true,
        attempt: 0,
        business: "medipet",
        contratante: {
          correo: this.requestToInter.contratante.datosContratante.correo, 
          datosContratante: this.requestToInter.contratante.datosContratante,
          datosPagador: this.requestToInter.contratante.datosPagador,
          direccion: this.requestToInter.contratante.direccion,
          etapa: "1",
          numeroCotizacion: "",
          pantalla: "5",
          plan: this.requestToInter.contratante.plan,
        },
        cupon: "",
        emision_id: parseInt(this.$route.params.emision_id),
        folio: this.paramsAseguradora.Folio,
        folioHash: "",
        insurance: "gmx",
        isEmailNeeded: true,
        isPago: this.paramsAseguradora.isPago == "true" ? true : false,
        mascotas: [],
        updateAt: moment().format("YYYY-MM-DD"),
      };
      form.mascotas.push({
        ...{...this.requestToInter.contratante.mascota[0], ...{
          poliza: {
            ...this.paramsAseguradora,
          },
        },},
      });
      delete form.mascotas[0].addPhoto
      delete form.mascotas[0].addPhoto
      delete form.contratante.datosPagador.pagador
      form.contratante.direccion.numeroExterior = parseInt(form.contratante.direccion.numeroExterior)
      form.contratante.direccion.numeroInterior = parseInt(form.contratante.direccion.numeroInterior)
      console.log({form})
      this.postLeadMascota(form)
    },
    async postLeadMascota(form){
      try{
        if(this.emision.status == 0 && !this.emision.poliza){
          await postLeadMascotaInter(form)
          await this.updatePolizaRequestJson()
          await this.sendPolizaMascota()
        }
      }
      catch{
        this.errorModal('Error al actualizar la poliza')
      }
      
    },
    getAseguradoraParams() {
      const url = window.location.hash;
      const queryString = url.split("?")[1];
      const params = new URLSearchParams(queryString);
      const paramsObj = {};
      params.forEach((value, key) => {
        paramsObj[key] = value;
      });
      this.paramsAseguradora = paramsObj;
      if (paramsObj.isPago == "true") {
        this.getEmision();
      }

      console.log(paramsObj);
    },
    async updatePolizaRequestJson(){
      this.requestToInter = JSON.parse(this.emision.request);
      this.polizaData = {
        id: this.emision.id ?? '',
        agente: this.emision.agenteId ?? '',
        anios: 1,
        aseguradora_id: 15,
        campania: this.getCampania(2),
        campania_id: this.getCampania(1),
        categoriaSelected: 'DAÑOS',
        forma_pago:6,
        fechaPrimerPago: this.emision.fechaPrimerPago ?? '',
        fin: moment(this.emision.fin).format("YYYY-MM-DD") ?? '',
        inicio:  moment(this.emision.inicio).format("YYYY-MM-DD") ?? '',
        leadId: null,
        montoPrimerPago: this.emision.primerPago ?? '',
        montoSubsecuente: '0',
        pago_id:1,
        notas: '',
        numeroCuotas:1,
        periodicidad:1,
        poliza:this.paramsAseguradora.Poliza ?? '',
        prima_neta:(this.emision.primaNeta).toString() ?? '',
        prima_total:(this.emision.primaNeta).toString() ?? '',
        productos:null,
        producto_id:this.emision.productoId ?? '',
        status: 3,
        cotizacion:1,
        operador_id:this.emision.operadorId ?? '',
        tipo_poliza: "Nueva",
        type: null,
        tipo_persona: this.emision.tipoPersona ?? '',
        razon_social: this.requestToInter.contratante.datosContratante.regimenFiscal ?? '',
        rfc: this.requestToInter.contratante.datosContratante.rfc ?? '',
        nombre: this.requestToInter.contratante.datosContratante.nombre ?? '',
        fecha_nacimiento:moment(this.requestToInter.contratante.datosContratante.fechaNacimiento, 'DD/MM/YYYY').format("YYYY-MM-DD"),
        edad: await this.getEdad(this.requestToInter.contratante.datosContratante.fechaNacimiento) ?? '',
        apellido_paterno: this.requestToInter.contratante.datosContratante.apellidoPaterno ?? '',
        apellido_materno: this.requestToInter.contratante.datosContratante.apellidoMaterno ?? '',
        genero: this.requestToInter.contratante.datosContratante.sexo == 'hombre' ? 'Masculino' :  'Femenino',
        statusCivil:'',
        telefono: this.requestToInter.contratante.datosContratante.telefono ?? '',
        codigo_postal: this.requestToInter.contratante.direccion.codigoPostal ?? '',
        estado_id:  await getFormatEstadoId(this.requestToInter.contratante.direccion.estado)?? '',
        calle: this.requestToInter.contratante.direccion.calle ?? '',
        correo: this.requestToInter.contratante.datosContratante.correo ?? '',
        correo_electronico: this.requestToInter.contratante.datosContratante.correo ?? '',
        num_exterior: this.requestToInter.contratante.direccion.numeroExterior ?? '',
        num_interior: this.requestToInter.contratante.direccion.numeroInterior ?? '',
        colonia: this.requestToInter.contratante.direccion.colonia ?? '',
        primer_pago: (this.emision.primaNeta).toString() ?? '',
        mascota:this.requestToInter.contratante.mascota[0],
        isMascota:true
      }
      await updatePolizaManual(this.polizaData)
    },

    getCampania(eleccion){
        var currentCampania = this.campaniasLeads.find((e) => {
          return (
            e.canal == 'AGENTES' && e.ramo == 'DAÑOS'
          );
        });
      return eleccion == 1 ? currentCampania.id : currentCampania.producto
    },
    errorModal(text){
      this.$toast.error(text, {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
      });
    },
    async getEdad(date) {
      const birthdayDate = moment(date,'DD/MM/YYYY');
      const today = moment();
      const years = today.diff(birthdayDate, "years");
      return years;
    },
    async sendPolizaMascota(){
      try {
          const responseJson = JSON.parse(this.emision.response)
          await sendPolizaMascota(responseJson.data.Referencia[0]);
          this.$toast.success('Poliza enviada con exito ! ', {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false
          });
      }catch{
        this.errorModal('No se pudo enviar la poliza en este momento intente desde los detalles de la poliza')
      }

    }
  },
  computed: {},
  async mounted() {
    await this.getCampaingLeadsList();
    await this.getAseguradoraParams();
    await this.getCotizacion();
  },
};
</script>
