<template>
  <div style="text-align: center; align-items: center;">
    <div style="text-align: -webkit-center; ">
      <v-img
        class="mx-5 responsive-img mb-2"
        contain
        :max-width="'100'"
        :src="greenCheck"
      ></v-img>
    </div>

    <span class="titulo-header" style="padding-top: 100px;">Pago exitoso</span>

    <div style="padding: 50px;">
      <span class="subtitulo-header-2 font-montserrat">
        Felicidades, hemos recibido tu pago y desde ahora disfrutas de las
        ventajas de pertenece a interprotecciones.
      </span>
      <br /><br />
      <span class="subtitulo-header-2 font-montserrat">
        Puedes consultar los detalles de tu emision
      </span>
      <v-row class="pt-5">
        <v-col cols="12">
          <v-btn class="common-botton" dark @click="irPoliza">AQUI</v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>

import greenCheck from "@/assets/greenCheck.png";

export default {
    data(){
        return {
            greenCheck: greenCheck
        }
    },
    methods:{
      irPoliza(){
        this.$router.push(`/detalles-emision/${this.$route.params.emision_id}`);  
      }
    }
}
</script>

