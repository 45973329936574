<template>
    <div style="text-align: center; align-items: center;">
      <div style="text-align: -webkit-center; ">
        <v-img
          class="mx-5 responsive-img mb-2"
          contain
          :max-width="'100'"
          :src="errorIcon"
        ></v-img>
      </div>
  
      <span class="titulo-header" style="padding-top: 100px;">Pago fallido</span>
  
      <div style="padding: 50px;">
        <span class="subtitulo-header-2 font-montserrat">
            Su pago ha sido rechazado por la aseguradora
        </span>
        <br /><br />
        <span class="subtitulo-header-2 font-montserrat">
        Puedes generar otra cotización para intentar emitir
        </span>
        <v-row class="pt-5">
          <v-col cols="12">
            <v-btn class="common-botton" dark @click="$router.push('/cotizacion/mascota')">AQUI</v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
  </template>
  
  <script>
  
  import errorIcon from "@/assets/errorIcon.png";
  
  export default {
      data(){
          return {
            errorIcon: errorIcon
          }
      }
  }
  </script>
  
  