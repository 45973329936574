import { mainAxios } from "@/mainAxios.js";
import { getAgenteAccessToken } from "../tokens.service";

const getInterToken = () => {
  const token = sessionStorage.interToken
  if (token) return token;
  throw new Error("No existe ningún token cargado de Inter");
};

export const getRazas = async (raceID) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
      "inter-token": getInterToken(),
    },
  };

  const serverResponse = await mainAxios.get(
    `/v1/mascota/races/${raceID}`,
    config
  );
  return serverResponse.data

};

export const getTaxType = async () => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
      "inter-token": getInterToken(),
    },
  };

  const serverResponse = await mainAxios.get(
    `v1/mascota/taxTypes`,
    config
  );
  return serverResponse.data.data.species

};

export const getCodePostInter = async (CP) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
      "inter-token": getInterToken(),
    },
  };

  const serverResponse = await mainAxios.get(
    `v1/mascota/code_post/${CP}`,
    config
  );
  return serverResponse.data.data.colonias

};

export const createEmisionAndReferenciaPago = async (data) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
      "inter-token": getInterToken(),
    },
  };

  const serverResponse = await mainAxios.post(
    `v1/mascota/emision`,
    data,
    config
  );
  return serverResponse.data.data

};

export const sendPolizaMascota = async (reference) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
      "inter-token": getInterToken(),
    },
  };

  const request = {
    business: "medipet",
    insurance: "gmx",
    Referencia: reference
  }

  const serverResponse = await mainAxios.post(
    `v1/mascota/documents`,
    request,
    config
  );
  return serverResponse.data.data

};






export const getQuattroCodificacionAPI = (params) => {
  let S4_KEY = 'uLmdvb2dsZS5jb20vY2hhdC1kYXRhYmFzZS05NTdjMSIsIm5hbWUiOiJIZWJlc';
  let m = "zl8mIfg3UrGeD1CvQu7UddMRtZ9eljnfGDTy+oOne/8=";
  let URL_PRO = "https://lb3.copsis.com/quattro-aseguradoras/QuattroCodificacionAPI";
  let URL_QA = "https://skynet.copsis.com:8181/quattro-aseguradoras/QuattroCodificacionAPI";
  let Production = false;

  switch (window.location.hostname) {
    case "localhost":
    case "asesores-digitales-develop.netlify.app":
    case "asesoresdigitales-pruebas.interify.mx":
      Production = false
      break;
    case "asesoresdigitales.interify.mx":
      Production = true
      break;
    default:
      Production = false
      break;
  }


  const itemsParam = params;
  const message = itemsParam[0].message;
  const opcion = itemsParam[1].opcion;
  const secret = itemsParam[2].secret;
  const referencia = itemsParam[3].referencia;
  const retorno = itemsParam[4].retorno;

  let stringParams = "?m=" + m + "&";
  stringParams += "s4_key=" + S4_KEY + "&"
  stringParams += "message=" + message + "&"
  stringParams += "opcion=" + opcion + "&"
  stringParams += "secret=" + secret + "&"
  stringParams += "referencia=" + referencia + "&"
  stringParams += "retorno=" + retorno


  let BASEURL = Production ? URL_PRO : URL_QA

  let response = mainAxios.get(BASEURL + stringParams).then(response => {
    if (response.status === 200) {
      return { success: true, response: response.data.sha256_HMAC }
    } else {
      return { error: true, response: response }
    }
  }).catch(e => {
    console.error(e)
    return { error: true, response: e }
  })

  return response;
}
