import { mainAxios } from "@/mainAxios.js";
import { getAgenteAccessToken } from "../tokens.service";

const getInterToken = () => {
  const token = sessionStorage.interToken
  if (token) return token;
  throw new Error("No existe ningún token cargado de Inter");
};

export const getEmisionByID = async (emisionId) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

  const serverResponse = await mainAxios.get(
    `/v1/emision/id/${emisionId}`,
    config
  );

  return serverResponse.data

};

export const postLeadMascotaInter = async (request) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
      "inter-token": getInterToken(),
    },
  };

  const serverResponse = await mainAxios.post(
    `/v1/mascota/lead`, request, config
  );

  return serverResponse.data

};

export const updatePolizaManual = async (form) => {
  console.log({form})
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

    const serverResponse = await mainAxios.post(
      '/v1/emision/manual/actualizar',
      form,
      config
    );
    
    return serverResponse

};

export const getFormatEstadoId = (estadoName) => {
  const estadoslist = [
    { id: 1, name: "Aguascalientes" },
    { id: 2, name: "Baja California" },
    { id: 3, name: "Baja California Sur" },
    { id: 4, name: "Campeche" },
    { id: 5, name: "Coahuila de Zaragoza" },
    { id: 6, name: "Colima" },
    { id: 7, name: "Chiapas" },
    { id: 8, name: "Chihuahua" },
    { id: 9, name: "Ciudad de México" },
    { id: 10, name: "Durango" },
    { id: 11, name: "Guanajuato" },
    { id: 12, name: "Guerrero" },
    { id: 13, name: "Hidalgo" },
    { id: 14, name: "Jalisco" },
    { id: 15, name: "Estado de México" },
    { id: 16, name: "Michoacán de Ocampo" },
    { id: 17, name: "Morelos" },
    { id: 18, name: "Nayarit" },
    { id: 19, name: "Nuevo León" },
    { id: 20, name: "Oaxaca" },
    { id: 21, name: "Puebla" },
    { id: 22, name: "Querétaro" },
    { id: 23, name: "Quintana Roo" },
    { id: 24, name: "San Luis Potosí" },
    { id: 25, name: "Sinaloa" },
    { id: 26, name: "Sonora" },
    { id: 27, name: "Tabasco" },
    { id: 28, name: "Tamaulipas" },
    { id: 29, name: "Tlaxcala" },
    { id: 30, name: "Veracruz de Ignacio de la Llave" },
    { id: 31, name: "Yucatán" },
    { id: 32, name: "Zacatecas" },
  ]

  const estado = estadoslist.find((e) => (e.name).toLocaleLowerCase() == (estadoName).toLocaleLowerCase())
  console.log(estado, estadoName.toLocaleLowerCase())

  return estado ? estado.id : 1
}
